<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      @shown="onShown"
      @hidden="onHidden"
    >
      <section
        class="invoice-preview-wrapper"
        style="position: relative"
      >
        <!-- Alert: No item found -->
        <b-alert
          variant="danger"
          :show="order === undefined"
        >
          <h4 class="alert-heading">
            Error fetching invoice data
          </h4>
          <div class="alert-body">
            No invoice found with this invoice id. Check
            <b-link
              class="alert-link"
              :to="{ name: 'facture-list' }"
            >
              invoice List
            </b-link>
            for other invoice.
          </div>
        </b-alert>
        <div
          v-if="isLoading === true"
          class="text-center mb-2"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
        </div>
        <b-row
          v-if="all"
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card
              v-for="(order) in allOrders"
              :id="order.id"
              :key="order.id"
              no-body
              class="invoice-preview-card pagebreak"
            >
              <div
                class="d-flex justify-content-between flex-wrap mr-1 ml-1"
              >

                <div class="invoice-preview">
                  <h1
                    class="mt-1"
                    style="color: #008B8B; font-size: 30px"
                  >
                    Bon de livraison
                    <span

                      class="invoice-title invoice-number"
                      style="color: #008B8B;"
                    >
                      N° : BL-{{ order.id }}
                    </span>
                  </h1>
                  <h3 style="color: black ">
                    Date:
                    <span
                      class="invoice-date"
                      style="color: black"
                    >
                      {{ formatFn(order.created_at) }}</span>
                  </h3>
                  <div class="d-flex justify-content-between mt-1">
                    <div>
                      <qrcode-vue
                        :value="order.id.toString()"
                        :size="size"
                        level="H"
                      />
                      <span>{{ order.id }}</span>

                    </div>
                    <div>
                      <barcode
                        height="30"
                        width="2"
                        :value="order.id.toString()"
                      />
                    </div>

                  </div>
                  <div v-if="order.external_barcode">
                    <qrcode-vue
                      :value="order.external_barcode.toString()"
                      :size="size"
                      level="H"
                    />
                    <span>{{ order.external_barcode }}</span>
                  </div>
                </div>

                <div style="text-align:end">
                  <div>
                    <img
                      src="@/assets/images/logo/logo.png"
                      width="250px"
                      height="100%"
                    >

                  </div>
                  <div class="mt-1">
                    <b-card-text
                      class="h4"
                      style="color: black"
                    >
                      <strong
                        style="color:#008B8B"
                      >{{ company.name }} :</strong> Société de livraison des colis
                    </b-card-text>
                    <b-card-text
                      class="h4"
                      style="color: black"
                    >
                      <strong
                        style="color:#008B8B"
                      >Matricule fiscale :</strong> {{ company.tax_identification_number }}
                    </b-card-text>
                    <b-card-text
                      class="h4"
                      style="color: black"
                    >
                      <strong
                        style="color:#008B8B"
                      >Adresse :</strong> {{ company.address }}
                    </b-card-text>
                    <b-card-text
                      class="h4"
                      style="color: black"
                    >
                      <strong
                        style="color:#008B8B"
                      >Tel :</strong> {{ company.phone1 }}
                    </b-card-text>

                  </div>

                </div>
              </div>

              <div class="d-flex flex-row justify-content-between mr-1 ml-1 mb-1">

                <div>

                  <div
                    style="border: 2px solid black;font-size: 18px;"
                  >
                    <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                      <span
                        style="font-weight:900; color: #008B8B"
                      >Client : </span> {{ order.name }}
                    </b-card-text>
                    <b-card-text class="ml-2 mr-2 mb-1">
                      <span
                        style="font-weight:900; color: #008B8B"
                      >Gouvernorat : </span>{{ order.governorate.name }} <span v-if="order.delegation != null"> {{ order.delegation.name }}</span>
                    </b-card-text>

                    <b-card-text class="ml-2 mr-2 mb-1">
                      <span
                        style="font-weight:900; color: #008B8B"
                      >Adresse :</span>
                      {{ order.address }}
                    </b-card-text>
                    <b-card-text class="ml-2 mr-2 mb-1">
                      <span
                        style="font-weight:900; color: #008B8B"
                      > téléphone :</span>
                      {{ order.phone1 }} / {{ order.phone2 }}
                    </b-card-text>
                  </div>
                </div>
                <div>

                  <div
                    class="ml-2"
                    style="border: 2px solid black;font-size: 18px;"
                  >

                    <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                      <span
                        style="font-weight:900; color: #008B8B"
                      >Fournisseur :</span> {{ order.supplier.username }}
                    </b-card-text>
                    <b-card-text class="ml-2 mr-2 mb-1">
                      <span
                        style="font-weight:900; color: #008B8B"
                      >Téléphone : </span>{{ order.supplier.phone }} / {{ order.supplier.phone2 }}
                    </b-card-text>
                    <b-card-text class="ml-2 mr-2 mb-1">
                      <span
                        style="font-weight:900; color: #008B8B"
                      >Adresse :</span>
                      {{ order.supplier.address }}
                    </b-card-text>
                    <b-card-text class="ml-2 mr-2 mb-1">
                      <span
                        style="font-weight:900; color: #008B8B"
                      > Matricule Fiscale :</span>
                      {{ order.supplier.tax_identification_number }}
                    </b-card-text>
                  </div>
                </div>

              </div>

              <!-- Invoice Description: Table -->
              <div class="mr-1 ml-1">
                <b-table-lite
                  class="ta"
                  style="font-size: 20px; border: 2px solid black"
                  responsive
                  :items="order.p"
                  :fields="['désignation', 'quantité']"
                >
                  <template #cell(taskDescription)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{ data.item.taskTitle }}
                    </b-card-text>
                    <b-card-text class="text-nowrap">
                      {{ data.item.taskDescription }}
                    </b-card-text>
                  </template>
                </b-table-lite>
              </div>

              <div class="mr-1 ml-1">
                <b-row>
                  <b-col>
                    <h1
                      style="color: #008B8B; font-size: 18px ; font-weight: bold"
                    >
                      Commentaire :
                      <span
                        style="font-size: 15px ; color:black;"
                      >
                        {{ order.note }}
                      </span>
                    </h1>
                    <p v-if="order.external_supplier_name && order.external_supplier_phone">
                      Fournisseur Externe: {{ order.external_supplier_name }} - {{ order.external_supplier_phone }}
                    </p>
                    <div>
                      <b-card-text
                        v-if="order.is_an_exchange ==true"
                        class="h4"
                        style="color:black"
                      >
                        <strong style="color:#008B8B">Échange :</strong> OUI
                      </b-card-text>
                      <b-card-text
                        v-if="order.is_fragile ==true"
                        class="h4"
                        style="color:black"
                      >
                        <strong style="color:#008B8B">Fragile :</strong> OUI
                      </b-card-text>
                      <b-card-text
                        v-if="order.is_discharge == true"
                        class="h4"
                        style="color:black"
                      >
                        <strong style="color:#008B8B">Décharge :</strong> OUI
                      </b-card-text>
                    </div>

                  </b-col>
                  <b-col
                    class="ml-2"
                    style="font-size: 20px"
                  >
                    <table
                      style="border: 2px solid #008B8B"
                    >
                      <tr class="badge-glow bg-info bg-lighten-5">
                        <th class="text-center">
                          Montant HT: {{ order.price_ht }}
                        </th>
                      </tr>
                      <tr class="badge-glow bg-primary bg-lighten-5"><th class="text-center">TVA 19 %: {{ order.tax }}
                      </th></tr>
                      <tr class="badge-glow bg-success bg-lighten-1"> <th class="text-center">Total TTC: {{ order.price_ttc }} DT
                      </th></tr>

                    </table>
                  </b-col>

                </b-row>
              </div>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>
              <!-- Button: Print -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="isLoading"
                variant="outline-secondary"
                class="mb-75"
                block
                @click="printInvoice"
              >
                Imprimer
              </b-button>

              <!-- Button: Edit -->
            </b-card>
          </b-col>
        </b-row>
      </section>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            Veuillez patienter...
          </p>
          Génération des bon des livraison en cours
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BSpinner,
  BOverlay,
  BIcon,
} from 'bootstrap-vue'
import VueBarcode from 'vue-barcode'
import QrcodeVue from 'qrcode.vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    QrcodeVue,
    barcode: VueBarcode,
    BSpinner,
    BOverlay,
    BIcon,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['all'],
  data() {
    return {
      size: 100,
      p: [],
      order: [],
      allOrders: [],
      company: {},
      isLoading: false,
      show: false,
    }
  },

  created() {
    this.company = storeAuth.state.company
    this.id = this.$route.params.id
    this.getOrders()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },

    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },

    addnewitem(qte, nom) {
      this.p.push({
        désignation: nom,
        quantité: qte,
      })
    },
    async getOrders() {
      this.isLoading = true
      for (let k = 0; k < this.all.length; k += 1) {
        // eslint-disable-next-line no-await-in-loop
        await axios.get(`/api/orders/detail/${this.all[k]}`).then(response => {
          this.order = response.data
        })
        if (this.order != null) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.order.products.length; i++) {
            this.p.push({
              désignation: this.order.products[i].product,
              quantité: this.order.products[i].quantity,
            })
            this.order.p = this.p
          }
          this.p = []
        }
        this.allOrders.push(this.order)
      }
      this.isLoading = false
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      // this.$refs.cancel.focus()
    },
    onHidden() {
      // Focus the show button when the overlay is removed
      // this.$refs.show.focus()
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor{
    background-color:#208a9d ;
  }
.contact {
  background-color: #208a9d;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
  .pagebreak {break-inside: avoid;}
  .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 22px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor{
    background-color:#208a9d ;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #208a9d;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
